import styled from "styled-components"

const Category = styled.span`
  display: block;
  font-size: 0.875rem;
  font-weight: var(--font-weight-normal);
  color: var(--color-gray-6);
`

export default Category
