import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"

import type { Query } from "Types/GraphQL"
import Layout from "Layouts/layout"
import SEO from "Components/seo"
import _ from "lodash"
import kebabCase from "lodash/kebabCase"
import { rhythm } from "Styles/typography"
import Category from "Styles/category"
import DateTime from "Styles/dateTime"
import Markdown from "Styles/markdown"

interface BlogPostProps {
  data: Query
}

const BlogPost: React.FC<BlogPostProps> = ({ data, pageContext }) => {
  const {next, previous} = pageContext
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark!
  const { title, desc, thumbnail, date, category } = frontmatter!
  const ogImagePath = thumbnail && thumbnail?.childImageSharp?.gatsbyImageData?.src

  return (
    <Layout>
      <SEO title={title} desc={desc} image={ogImagePath!} />
      <main>
        <article>
          <OuterWrapper>
            <InnerWrapper>
              <div>
                <header>
                  <Info>
                  <Link to={`/${kebabCase(category)}`}>{category}</Link>
                    {/*<PostCategory>{category}</PostCategory>*/}
                    {/*<Time dateTime={date}>{date}</Time>*/}
                  </Info>
                  <Title>{title}</Title>
                  <Desc>{desc}</Desc>
                </header>
                {<Divider />}
                <Markdown
                  dangerouslySetInnerHTML={{ __html: html ?? "" }}
                  rhythm={rhythm}
                />
              </div>
              <PaginationR>
              {previous && 
                <Link to={previous.fields.slug}>
                 {previous.frontmatter.title} →
                </Link>
              }
              </PaginationR>
              <PaginationL>
              {next && 
                <Link to={next.fields.slug}>
                  ← {next.frontmatter.title}
                </Link>
              }
              </PaginationL>
            </InnerWrapper>
          </OuterWrapper>
        </article>
      </main>
    </Layout>
  )
}

const OuterWrapper = styled.div`
  background-color: #d4e2fb;
  background-image: linear-gradient(315deg, #d4e2fb 0%, #fdd7f2 100%);
  @media (max-width: ${({ theme }) => theme.device.sm}) {
  }
`

const InnerWrapper = styled.div`
  width: var(--post-width);
  margin: 0 auto;
  padding: 3rem 12rem 8rem 12rem;
  border-radius: 12px;
  background-color: var(--color-white);
  @media (max-width: ${({ theme }) => theme.device.md}) {
    width: 95%;
    padding: 2rem;
    padding-bottom: 5rem;
  }
  @media (max-width: ${({ theme }) => theme.device.sm}) {
    width: 100%
    padding: 2rem;
    padding-bottom: 5rem;
  }
`

const CommentWrap = styled.section`
  width: 100%;
  padding: 0 var(--padding-sm);
  margin: 0 auto;
  margin-bottom: var(--sizing-xl);
  @media (max-width: ${({ theme }) => theme.device.sm}) {
    width: auto;
  }
`

const PostCategory = styled(Category)`
  font-size: var(--text-xs);
  font-weight: var(--font-weight-semi-bold);
`

const Info = styled.div`
  margin-bottom: var(--sizing-md);
`

const Time = styled(DateTime)`
  display: block;
  margin-top: var(--sizing-xs);
`

const Desc = styled.p`
  line-height: var(--text-md);
  font-size: var(--text-md);
  padding-bottom: var(--padding-lg)
  @media (max-width: ${({ theme }) => theme.device.sm}) {
    line-height: var(--text-md);
  }
`

const PaginationL = styled.div`
  width: 50%;
  float: left;
  text-decoration: none;
  margin-top: var(--sizing-lg);
  margin-bottom: var(--sizing-lg);
`
const PaginationR = styled.div`
  width: 50%;
  float: right;
  text-align: right;
  text-decoration: none;
  margin-top: var(--sizing-lg);
  margin-bottom: var(--sizing-lg);
`

const Divider = styled.div`
  width: 100%;
  margin-top: var(--sizing-lg);
  margin-bottom: var(--sizing-lg);
`

const Title = styled.h1`
  font-size: var(--text-xxxl);
  line-height: var(--text-md);
  font-family: var(--font-display);
  @media (max-width: ${({ theme }) => theme.device.sm}) {
    font-size: var(--text-xl);
  }
`

export const query = graphql`query ($slug: String!) {
  markdownRemark(
    fields: {slug: {eq: $slug}}
    ) {
    html
    frontmatter {
      slug
      title
      desc
      thumbnail {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FIXED)
        }
      }
      date(formatString: "YYYY-MM-DD")
      category
    }
  }
}
`

export default BlogPost
